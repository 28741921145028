import React from "react";
import { get, isEmpty } from "utils/lodashExtracts";
import CharlesMeta from "components/common/meta/CharlesMeta";
import Segment from "utils/segment";
import { Container } from "react-bootstrap";
import FooterDepartments from "components/common/FooterDepartments";
import FooterCities from "components/common/FooterCities";
import { Link, graphql } from "gatsby";
import Section from "components/common/Section";

import { Layout } from "components/common";
import Cta from "components/common/Cta";
import PressBanner from "components/Press/PressBanner";
import TopSection from "templates/Pathologies/common/TopSection";
import AboutPathology from "templates/Pathologies/common/AboutPathology";
import TestimonyPathologyTopBottom from "templates/Pathologies/common/Testimonies/TestimonyPathologyTopBottom";
import Treatments from "templates/Pathologies/common/Treatments";
import HowItWorksSection from "templates/Pathologies/common/HowItWorksSection";
import ScientistSection from "templates/Pathologies/common/ScientistSection";
import StructuredWebPage, { cityAsAreaServed } from "components/common/meta/StructuredWebPage";
import T from "types/index";
import StructuredFAQ from "components/common/meta/StructuredFAQ";
import Breadcrumb, { annuaryCityBreadcrumbs } from "templates/Blog/common/Breadcrumb";

const anchors = (scientists: T.Charles.ScientistRecord[]) => {
  const scientistLabel =
    isEmpty(scientists) || scientists.length > 1
      ? "Comité scientifique"
      : "Directeur Scientifique";
  return [
    { id: "how-it-works-anchor", label: "Comment ça marche ?" },
    { id: "about-anchor", label: "À propos" },
    { id: "treatments-anchor", label: "Nos traitements" },
    { id: "testimony-anchor", label: "Résultats" },
    { id: "scientist-anchor", label: scientistLabel },
  ];
};
type ResultList<ResultType> = {
  nodes: ResultType[]
}
type Props = {
  data: {
    cityRecord: T.Charles.CityRecord;
    templateDataRecord: T.Charles.ProductTemplateDataRecord;
    categoryRecord: T.Charles.CategoryRecord
    otherCities: ResultList<T.Charles.CityRecord>,
    closeCities: ResultList<T.Charles.CityRecord>,
    closeDepartments: ResultList< T.Charles.DepartmentRecord>
  },
}

const AnnuaireSexoCity = ({
  data: { cityRecord, templateDataRecord, categoryRecord, otherCities, closeCities, closeDepartments },
}: Props) => {
  const city: T.Charles.City = cityRecord.data;
  const templateData: T.Charles.ProductTemplateData = templateDataRecord.data;
  const category: T.Charles.Category = categoryRecord.data;
  const closeCitiesRecords = closeCities.nodes;
  const otherCitiesRecords = otherCities.nodes;
  const closeDepartmentsRecords = closeDepartments.nodes
  const cta = (
    <Cta
      text="Consultez un sexologue"
      className="new-primary-cta pathology-cta"
      path="/consulter-sexualite/"
    />
  );
  const aboutItems: T.Standard.AccordionItem[] = [
    {
      title: city.filler_city_about_1_title,
      text: city.filler_city_about_1_text,
    },
    {
      title: city.filler_city_about_2_title,
      text: city.filler_city_about_2_text,
    },
    {
      title: city.filler_city_about_3_title,
      text: city.filler_city_about_3_text,
    },
  ];
  const itemsToQA = (item: T.Standard.AccordionItem) => ({
    question: item.title,
    answer: item.text,
  });
  const allQA = aboutItems.map(itemsToQA);
  return (
    <Layout showLinksInFooter={false}>
      <CharlesMeta
        data={{
          title: city.filler_city_meta_title,
          description: city.filler_city_meta_description,
        }}
        segmentCategory={Segment.CATEGORIES.ANNUARY}
      />
      <StructuredWebPage
        title={city.filler_city_h1.internal.content}
        description={city.filler_city_meta_description}
        areaServed={cityAsAreaServed(city)}
        sexoWebsiteDescription
      />
      <StructuredFAQ data={allQA} />
      <div className="pathology-template" id="pathology-template">
        <TopSection
          h1={city.filler_city_h1}
          subtitle={templateData.subtitle}
          cta_label={templateData.cta_label}
          start_slug={templateData.start_slug}
          border_icon={category.border_icon}
          main_video={templateData.main_video}
          reinsurance_items={templateData.reinsurance_items}
          cta={cta}
          anchors={anchors(templateData.scientists)}
        />
        <HowItWorksSection
          how_title={city.filler_city_how_to_title}
          how_items={templateData.how_items}
          start_slug={templateData.start_slug}
          cta={cta}
        />
        <PressBanner withBorders className="mb-0" />
        <AboutPathology
          about_title={city.filler_city_about_title}
          about_definition={city.filler_city_definition}
          bottomBorder={false}
          aboutItems={aboutItems}
        />
        <Treatments
          treatments_title={templateData.treatments_title}
          treatments_text={templateData.treatments_text}
          treatments_list={templateData.treatments_list}
          start_slug={templateData.start_slug}
          cta={cta}
        />
        <TestimonyPathologyTopBottom
          testimonies_background={templateData.testimonies_background}
          testimonies_percent={templateData.testimonies_percent}
          testimonies_list={templateData.testimonies_list}
          cta_label={templateData.cta_label}
          start_slug={templateData.start_slug}
        />
        <ScientistSection
          scientists_title={templateData.scientists_title}
          scientists={templateData.scientists}
          cta_label={templateData.cta_label}
          start_slug={templateData.start_slug}
        />
        <Section className="annuary-city-section landing-section-white mt-5">
          <Container className="px-0 annuary-city-description">
            <Breadcrumb links={annuaryCityBreadcrumbs(city)} />
            <div className="d-lg-flex justify-content-lg-center"></div>
            <h2>
              En savoir plus sur les consultations des médecins sexologues{" "}
              {city.A_LIEU}
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: city.spinner.childMarkdownRemark.html,
              }}
            />
          </Container>
        </Section>
        <Section className="">
          <Container className="px-0 pb-5">
            <h2 className="h2 text-left mb-4">Départements à proximité</h2>
            <FooterDepartments departments={closeDepartmentsRecords}/>
          </Container>
        </Section>
        <Section className="landing-section-white pb-0">
          <Container className="px-0 pb-5">
            <h2 className="h2 text-left mb-4">Villes à proximité</h2>
            <FooterCities cities={closeCitiesRecords}/>
          </Container>
        </Section>
        <Section className="">
          <Container className="px-0 pb-5">
            <h2 className="h2 text-left mb-4 pt-5">Autres Villes</h2>
            <FooterCities cities={otherCitiesRecords}/>
            <div className="pb-5"></div>
          </Container>
        </Section>
      </div>
    </Layout>
  );
};

export default AnnuaireSexoCity;

export const pageQuery = graphql`
  query (
    $id: String!
    $closeCitiesId: [String]!
    $otherCitiesId: [String]!
    $closeDepartmentsId: [String]!
  ) {
    cityRecord: airtableCities(id: { eq: $id }) {
      id
      data {
        name
        slug
        status
        external_url
        A_LIEU
        lat
        lng
        filler_city_meta_title
        filler_city_meta_description
        filler_city_about_1_title
        filler_city_about_1_text
        filler_city_about_2_title
        filler_city_about_2_text
        filler_city_about_3_title
        filler_city_about_3_text
        filler_city_h1 {
          ...AirtableMarkdownFields
        }
        filler_city_definition {
          ...AirtableMarkdownFields
        }
        filler_city_how_to_title {
          ...AirtableMarkdownFields
        }
        filler_city_about_title {
          ...AirtableMarkdownFields
        }
        spinner {
          ...AirtableMarkdownFields
        }
        departments {
          id
          data {
            name
            slug
            A_LIEU
          }
        }
      }
    }
    templateDataRecord: airtableProductTemplateData(
      data: { code_name: { eq: "annuaire-sexo-ville" } }
    ) {
      ...AirtableProductTemplateDataFields
    }
    categoryRecord: airtableCategories(
      data: { slug: { eq: "sante-sexuelle" } }
    ) {
      id
      data {
        border_icon { ...AirtableImagesConstrainedFields }
      }
    }
    closeCities: allAirtableCities(filter: { id: { in: $closeCitiesId }}) {
      nodes {
        ...AirtableCityLinkFields
      }
    }
    otherCities: allAirtableCities(filter: { id: { in: $otherCitiesId }}) {
      nodes {
        ...AirtableCityLinkFields
      }
    }
    closeDepartments: allAirtableDepartments(filter: { id: { in: $closeDepartmentsId }}) {
      nodes {
        ...AirtableDepartmentLinkFields
      }
    }
  }
  fragment AirtableCityLinkFields on AirtableCities {
    id
    data {
      slug
      name
      departments {
        data {
          slug
        }
      }
    }
  }
  fragment AirtableDepartmentLinkFields on AirtableDepartments {
    id
    data {
      slug
      A_LIEU
    }
  }
`;
